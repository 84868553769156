var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoadingData)?_c('div',{staticClass:"pa-5 flex-grow-1"},[_c('div',[_c('v-subheader',[_vm._v("General permissions")]),_c('div',{staticClass:"team-modules-container"},_vm._l((_vm.generalPermissionOptions),function(permissionOption){return _c('div',{key:permissionOption.column,staticClass:"team-module d-flex flex-column align-center justify-center ant-glass-background",class:{
          'team-module-active': _vm.isGeneralPermissionActive(
            permissionOption.column
          ),
        }},[_c('div',{staticClass:"team-module-overlay"},[(!_vm.selectedTeam.is_admin)?_c('v-btn',{staticClass:"team-module-overlay-btn",attrs:{"small":""},on:{"click":function($event){return _vm.updateGeneralPermission(
                permissionOption.column,
                !_vm.generalPermissions[permissionOption.column]
              )}}},[_vm._v(" "+_vm._s(_vm.isGeneralPermissionActive(permissionOption.column) ? 'disable' : 'enable')+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle-off-outline ")])]}}],null,true)},[_c('span',[_vm._v("Cannot disable for admin team")])])],1),_c('v-icon',{class:{
            inactive: !_vm.isGeneralPermissionActive(permissionOption.column),
          },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(permissionOption.icon)+" ")]),_c('div',{class:{
            inactive: !_vm.isGeneralPermissionActive(permissionOption.column),
          }},[_vm._v(" "+_vm._s(permissionOption.column)+" ")])],1)}),0)],1),_c('div',[_c('v-subheader',[_vm._v("Workflows permissions")]),_c('div',{staticClass:"team-modules-container"},_vm._l((_vm.workflowPermissionOptions),function(permissionOption){return _c('div',{key:permissionOption.column,staticClass:"team-module d-flex flex-column align-center justify-center ant-glass-background",class:{
          'team-module-active':
            (_vm.isWorkflowPermissionActive(permissionOption.column) &&
              _vm.generalPermissions.workflows) ||
            _vm.selectedTeam.is_admin,
        }},[_c('div',{staticClass:"team-module-overlay"},[(_vm.generalPermissions.workflows && !_vm.selectedTeam.is_admin)?_c('v-btn',{staticClass:"team-module-overlay-btn",attrs:{"small":""},on:{"click":function($event){return _vm.updateWorkflowPermission(
                permissionOption.column,
                !_vm.workflowPermissions[permissionOption.column]
              )}}},[_vm._v(" "+_vm._s(_vm.isWorkflowPermissionActive(permissionOption.column) ? 'disable' : 'enable')+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle-off-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.selectedTeam.is_admin ? 'Cannot disable for admin team' : 'General module is disabled cannot set permissions'))])])],1),_c('v-icon',{class:{
            inactive:
              (!_vm.isWorkflowPermissionActive(permissionOption.column) ||
                !_vm.generalPermissions.workflows) &&
              !_vm.selectedTeam.is_admin,
          },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(permissionOption.icon)+" ")]),_c('div',{class:{
            inactive:
              (!_vm.isWorkflowPermissionActive(permissionOption.column) ||
                !_vm.generalPermissions.workflows) &&
              !_vm.selectedTeam.is_admin,
          }},[_vm._v(" "+_vm._s(permissionOption.column)+" ")])],1)}),0)],1),_c('div',[_c('v-subheader',[_vm._v("SBS permissions")]),_c('div',{staticClass:"team-modules-container"},_vm._l((_vm.sbsPermissionOptions),function(permissionOption){return _c('div',{key:permissionOption.column,staticClass:"team-module d-flex flex-column align-center justify-center ant-glass-background",class:{
          'team-module-active': _vm.isSbsPermissionActive(
            permissionOption.column
          ),
        }},[_c('div',{staticClass:"team-module-overlay"},[(_vm.generalPermissions.sbs)?_c('v-btn',{staticClass:"team-module-overlay-btn",attrs:{"small":""},on:{"click":function($event){return _vm.updateSbsPermission(
                permissionOption.column,
                !_vm.sbsPermissions[permissionOption.column]
              )}}},[_vm._v(" "+_vm._s(_vm.isSbsPermissionActive(permissionOption.column) ? 'disable' : 'enable')+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle-off-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.selectedTeam.is_admin ? 'Cannot disable for admin team' : 'General module is disabled cannot set permissions'))])])],1),_c('v-icon',{class:{
            inactive: !_vm.isSbsPermissionActive(permissionOption.column),
          },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(permissionOption.icon)+" ")]),_c('div',{class:{
            inactive: !_vm.isSbsPermissionActive(permissionOption.column),
          }},[_vm._v(" "+_vm._s(permissionOption.column)+" ")])],1)}),0)],1),_c('div',[_c('v-subheader',[_vm._v("Modules")]),_c('div',{staticClass:"team-modules-container"},_vm._l((_vm.project.modules),function(module){return _c('div',{key:module.id,staticClass:"team-module d-flex flex-column align-center justify-center ant-glass-background",class:{ 'team-module-active': _vm.isModuleActive(module) }},[_c('div',{staticClass:"team-module-overlay"},[(!_vm.selectedTeam.is_admin)?_c('v-btn',{staticClass:"team-module-overlay-btn",attrs:{"small":"","loading":module.loading},on:{"click":function($event){return _vm.clickModuleForTeam(module)}}},[_vm._v(" "+_vm._s(_vm.isModuleActive(module) ? 'disable' : 'enable')+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle-off-outline ")])]}}],null,true)},[_c('span',[_vm._v("Cannot disable for admin team")])])],1),_c('v-icon',{class:{ inactive: !_vm.isModuleActive(module) },attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(module.icon ? module.icon : 'mdi-application')+" ")]),_c('div',{class:{ inactive: !_vm.isModuleActive(module) }},[_vm._v(" "+_vm._s(module.name)+" ")])],1)}),0)],1)]):_c('div',{staticClass:"d-flex justify-center align-center fill-height flex-1"},[_c('ant-loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }