<template>
  <div ref="table-container" class="flex-grow-1">
    <dynamic-data-table
      table-title=""
      :table-headers="tableHeaders"
      :table-records="tableRecords"
      :is-loading="loadingTable"
      :table-height="`${tableHeight}px`"
    >
      <template #item.create="{ value, item }">
        <v-checkbox
          :input-value="value"
          :disabled="isDisabled"
          :ripple="false"
          hide-details
          @change="onPermissionChange(value, item, 'create')"
        />
      </template>
      <template #item.update="{ value, item }">
        <v-checkbox
          :input-value="value"
          :disabled="isDisabled"
          :ripple="false"
          hide-details
          @change="onPermissionChange(value, item, 'update')"
        />
      </template>
      <template #item.read="{ value, item }">
        <v-checkbox
          :input-value="value"
          :disabled="isDisabled"
          :ripple="false"
          hide-details
          @change="onPermissionChange(value, item, 'read')"
        />
      </template>
      <template #item.delete="{ value, item }">
        <v-checkbox
          :input-value="value"
          :disabled="isDisabled"
          :ripple="false"
          hide-details
          @change="onPermissionChange(value, item, 'delete')"
        />
      </template>
      <template #item.configure="{ value, item }">
        <v-checkbox
          :input-value="value"
          :disabled="isDisabled"
          :ripple="false"
          hide-details
          @change="onPermissionChange(value, item, 'configure')"
        />
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';

export default {
  name: 'ProjectTeamPermissions',
  components: { DynamicDataTable },
  props: {
    selectedTeam: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      tableHeaders: [
        {
          text: 'table',
          value: 'table',
        },
        {
          text: 'create',
          value: 'create',
          hasSlot: true,
        },
        {
          text: 'read',
          value: 'read',
          hasSlot: true,
        },
        {
          text: 'update',
          value: 'update',
          hasSlot: true,
        },
        {
          text: 'delete',
          value: 'delete',
          hasSlot: true,
        },
        {
          text: 'configure',
          value: 'configure',
          hasSlot: true,
        },
      ],
      tableRecords: [],
      tableHeight: undefined,
    };
  },
  computed: {
    ...mapGetters(['project', 'projectTables', 'teamPermissions']),

    isAdminTeam() {
      return this.selectedTeam.is_admin;
    },
    loadingTable() {
      return (
        this.$wait.is('project.tables.get') ||
        this.$wait.is('team.permissions.get')
      );
    },
    isDisabled() {
      return (
        this.isAdminTeam ||
        this.$wait.is('team.table.permission.create') ||
        this.$wait.is('team.table.permission.update')
      );
    },
  },
  watch: {
    selectedTeam: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
    teamPermissions: {
      handler() {
        this.getTableRecords();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.tableHeight = this.$refs['table-container'].offsetHeight - 64 - 59;
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchProjectTables', this.project.id).then(() => {
        this.$store.dispatch('getTeamPermissions', {
          teamId: this.selectedTeam.id,
          projectId: this.project.id,
        });
      });
    },
    getTableRecords() {
      let data = [];

      this.projectTables.forEach((table) => {
        let tablePermission = this.teamPermissions.find(
          (item) => item.table === table.id
        );
        if (tablePermission) {
          data.push({
            table: table.name,
            table_id: table.id,
            permissionId: tablePermission.id ?? null,
            create: this.isAdminTeam ? true : !!tablePermission.create ?? false,
            read: this.isAdminTeam ? true : !!tablePermission.read ?? false,
            update: this.isAdminTeam ? true : !!tablePermission.update ?? false,
            delete: this.isAdminTeam ? true : !!tablePermission.delete ?? false,
            configure: this.isAdminTeam
              ? true
              : !!tablePermission.configure ?? false,
          });
        } else {
          data.push({
            table: table.name,
            table_id: table.id,
            permissionId: null,
            create: this.isAdminTeam,
            read: this.isAdminTeam,
            update: this.isAdminTeam,
            delete: this.isAdminTeam,
            configure: this.isAdminTeam,
          });
        }
      });
      this.tableRecords = data;
    },
    onPermissionChange(value, item, type) {
      let body = {
        project: {
          id: this.project.id,
        },
        table: {
          id: item.table_id,
        },
        group: {
          id: this.selectedTeam.id,
        },
      };
      const permissions = {
        create: item.create ?? false,
        read: item.read ?? false,
        update: item.update ?? false,
        delete: item.delete ?? false,
        configure: item.configure ?? false,
      };
      permissions[type] = !value;
      body = { ...body, ...permissions };

      if (item.permissionId) {
        this.$store
          .dispatch('updateTeamPermission', {
            permissionId: item.permissionId,
            body,
          })
          .then(() => {
            this.fetchData();
          });
      } else {
        this.$store
          .dispatch('createTeamPermission', { body })
          .then(() => this.fetchData());
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
